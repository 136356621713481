import Nav from './Nav'

function AnimesEditor() {
  return (
    <main className='mainContainer' style={{ height: '3000px' }}>
      <Nav routeActive='animes' />
      <div>animesEditor</div>
    </main>
  )
}

export default AnimesEditor
